<template>
  <div>
    <trev-cool-table
      :tableId="'system-user-list'"
      :title="'Users'"
      :courier="$courier.SystemUsers"
      :headers="getHeaders"
      :addNewLink="{ name: 'Add System User' }"
    >
      <template v-slot:item.name="{ item }">
        {{ item.firstName + " " + item.lastName }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          color="info"
          :to="{ name: 'View System User', params: { userId: item.id } }"
          class="mr-2"
        >
          <v-icon class="mr-2"> mdi-magnify </v-icon>
          View User
        </v-btn>
        <v-btn
          small
          color="danger"
          @click="
            showDeleteUser = true;
            selectedUser = item;
          "
        >
          <v-icon class="mr-2"> mdi-delete-outline </v-icon>
          Delete User
        </v-btn>
      </template>
    </trev-cool-table>
    <trev-confirm-delete-dialog
      :show.sync="showDeleteUser"
      :itemToDelete="selectedUser.firstName + ' ' + selectedUser.lastName"
      @dialog-delete-deleted="deleteUser"
      @dialog-delete-cancelled="cancelDeleteUser"
    >
    </trev-confirm-delete-dialog>
  </div>
</template>

<script>
import TrevConfirmDeleteDialog from "../base/dialog/trev-confirm-delete-dialog.vue";
import trevCoolTable from "../tables/trev-cool-table.vue";
export default {
  components: { trevCoolTable, TrevConfirmDeleteDialog },
  computed: {
    getHeaders() {
      return this.headers;
    },
  },
  methods: {
    deleteUser() {
      this.$courier.SystemUsers.removeById(this.selectedUser.id).then((x) => {
        this.$eventBus.$emit("system-user-list-refresh");
        this.selectedUser = {};
      });
    },
    cancelDeleteUser() {
      this.selectedUser = {};
    },
  },
  data() {
    return {
      selectedUser: {},
      showDeleteUser: false,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Job Title",
          value: "jobTitle",
        },
        {
          text: "Email",
          value: "emailAddress",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style>
</style>