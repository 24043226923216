<template>
  <div>
      <trev-system-user-list></trev-system-user-list>
  </div>
</template>

<script>
import TrevSystemUserList from '../../../../components/systemusers/trev-system-user-list.vue'
export default {
    components: {
        TrevSystemUserList
    },
}
</script>

<style>

</style>